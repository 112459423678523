@import 'variables';

// Home page
.home-page {
    background-color: $ld-light-gray;
    color: $ld-gray;
    width: 100vw;
    min-height: 66vh;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;

    .splash_header {
        text-align: left;
        font-family: FordF1-Medium;
        margin-bottom: 10px;
        padding: 2rem;
        padding-bottom: 1.5rem;
        padding-top: 1.5rem;

        p {
            padding: 0;
            margin: 0;
            font-size: 2rem;
            color: $ford-blue;
        }

        span {
            color: $ford-skyview;
        }
    }

    a:not(.k-button) {
        color: $link-color;
    }

    .content {
        padding: 0;
        padding-top: 1rem;
        margin: 0 auto;
        position: relative;
        // max-width: 670px;
        display: flex;
        flex-direction: column;
        width: 100vw;
    }

    .body_container {
        margin-top: 1rem;

        .inner_container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 0;
            padding: 2rem;
            padding-top: 0;
            // width: 90%;

            .text_container {
                span {
                    font-family: FordF1-Bold;
                    color: $ford-blue;
                }

                .waitlist_text{
                    color: $input-error;
                    padding: 0;
                    margin: 0;
                    font-size: 1rem;
                    font-family: FordF1-Medium;
                    text-align: left;
                    margin-bottom: 1rem;
                }
            }

            .logo {
                margin: auto;
                display: block;
                max-width: 270px;
                width: 30%;
            }
        }

        p {
            padding: 0;
            margin: 0;
            font-size: 1rem;
            color: $ford-blue;
            font-family: FordF1-Medium;
            text-align: left;
            margin-bottom: 1rem;
        }
    }

    .img_container {
        margin: 0;
        padding-left: 2rem;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        .banner_img {
            margin: 0;
            padding: 0;
            border-radius: 20px;
            width: 99%;
            // max-height: 500px;
            max-width: 600px;
        }
    }


    .home-title {
        margin: auto;
        display: block;
    }

    // span {
    //     color: $ld-gray;
    // }

    .button-group {
        // align content to the left
        display: flex;
        justify-content: flex-start;
        width: 90%;
        padding-left: 2rem;

        .home_screen_btn {
            font-family: FordF1-Medium;
            font-size: 14px;
            color: $white;
            margin: 0 20px 32px 20px;
            background-color: $ford-skyview;
            border-style: none;
            border-radius: 400px;
            box-sizing: content-box;
            // text-transform: uppercase;
            cursor: pointer;
            line-height: 20px;
            text-align: center;
            // display: inline;
            height: 28px;
            padding: 12px 16px 12px 16px;
            perspective-origin: 0 0;
            text-decoration: none;
            transform-origin: 0 0;
            width: 135px;
            // -moz-appearance: none;
            // -webkit-logical-height: 1em;
            // /* Chrome ignores auto, so we have to use this hack to set the correct height  */
            // -webkit-logical-width: auto;
            /* Chrome ignores auto, but here for completeness */
        }

        .home_screen_btn_left {
            margin: 0 20px 32px 0px;
        }

        .home_screen_btn:hover {
            background-color: $button-hover;
        }

        /* Mozilla uses a pseudo-element to show focus on buttons, */
        /* but anchors are highlighted via the focus pseudo-class. */

        @supports (-moz-appearance:none) {

            /* Mozilla-only */
            home_screen_btn::-moz-focus-inner {
                /* reset any predefined properties */
                border: none;
                padding: 0;
            }

            home_screen_btn:focus {
                /* add outline to focus pseudo-class */
                outline-style: dotted;
                outline-width: 1px;
            }
        }
    }
}

@media (max-width: 500px) {
    .inner_container {
        padding: 1rem;
        flex-direction: column;
    }

    .splash_header {
        padding: 1rem !important;
    }

    .logo {
        width: 50% !important;
    }

    .button-group {
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .home_screen_btn_left {
            margin-right: 0 !important;
        }
    }
}