// Normalization
body,
h1,
h2,
h3 {
    margin: 0;
    padding: 0;
}

body {
    margin: 0 auto;
    padding: 0px;
}

a {
    text-decoration: none;
}

// App styles
.k-drawer-container {
    background-color: $background-color;

    .k-drawer {
        box-shadow: 0 0 10px rgba(0, 0, 0, .2);
        border-right-width: 0 !important;
    }

    &.k-drawer-push .k-drawer {
        position: sticky;
        top: 80px;
        height: calc(100vh - 80px);
    }

    .k-drawer-item {
        user-select: none;
    }
}

.k-form-error {
    color: $input-error;
}

.k-form-field {
    margin-bottom: 1rem;
    flex-direction: column;

    .k-label {
        margin-bottom: 5px;
        align-items: flex-start;
        color: $ford-blue !important;
        font-family: FordF1-Regular;
        width: auto;
    }

    .k-textbox {
        border: 1px solid #808080;
        border-radius: 8px;
        line-height: 20px;
        padding: 8px 12px 8px 12px;
        font-size: 14px;
        font-family: FordF1-Regular;
    }

    .k-state-invalid {
        border: 2px solid $input-error;
        border-radius: 8px;
    }

    .k-form-error {
        color: $input-error;
    }
    .k-dropdown-wrap .k-picker-wrap {
        background-color: white;
        border: 1px solid #808080;
        border-radius: 8px;
    }

}

.standard_btn {
    font-family: FordF1-Medium;
    font-size: 14px;
    color: $white;
    margin: 0;
    margin-bottom: 1rem;
    background-color: $ford-skyview;
    border-style: none;
    border-radius: 400px;
    box-sizing: content-box;
    // text-transform: uppercase;
    cursor: pointer;
    line-height: 20px;
    text-align: center;
    // display: inline;
    height: 28px;
    padding: 12px 16px 12px 16px;
    perspective-origin: 0 0;
    text-decoration: none;
    transform-origin: 0 0;
    width: 135px;
    margin-top: 1rem;
    // -moz-appearance: none;
    // -webkit-logical-height: 1em;
    // /* Chrome ignores auto, so we have to use this hack to set the correct height  */
    // -webkit-logical-width: auto;
    /* Chrome ignores auto, but here for completeness */
}

.standard_btn:hover {
    background-color: $button-hover;
}

.card-container {
    background-color: $white;
    padding: 40px 0;
    text-align: center;

    &.grid {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        column-gap: 10px;
        row-gap: 1em;
    }

    .card-title {
        font-size: 25px;
        margin-bottom: 20px;
        text-align: center;
        font-family: FordF1-Medium;
    }

    .card-text {
        text-align: center;
        max-width: 300px;
        margin: 20px auto;
    }

    .card-buttons {
        .k-button {
            color: #ffffff;
            border: 1px;
            border-radius: 20px;
            border-color: $ld-ford-blue;
            background-color: $ld-ford-blue;
            min-height: 40px;
            font-size: 1.25em;

            min-width: 115px;
            text-align: center;
            margin: 10px 0 2px 0;
        }

        .k-button:hover {
            border-color: $ld-ford-dark-blue;
            background-color: $ld-ford-dark-blue;
        }
    }

    .card-ranges {
        .k-daterangepicker {

            .k-textbox-container,
            .k-floating-label-container {
                padding-top: 0;
                min-width: 105px;
            }

            .k-label {
                display: none;
            }
        }
    }

    .card-component {
        grid-column: 1 / -1;
        overflow: hidden;

        li {
            padding: 10px 0;
        }
    }

    .card-links {
        .k-link {
            margin: 15px auto 17px auto;
            align-items: normal;
            border: 0;
            background-color: transparent;
            background-image: none;
            box-sizing: content-box;
            text-transform: uppercase;
            cursor: pointer;
            display: block;
            font: inherit;
            font-size: 1em;
            color: $ld-ford-blue;
            height: auto;
            padding: 0;
            perspective-origin: 0 0;
            text-align: start;
            text-decoration: none;
            transform-origin: 0 0;
            width: auto;
            -moz-appearance: none;
            -webkit-logical-height: 1em;
            /* Chrome ignores auto, so we have to use this hack to set the correct height  */
            -webkit-logical-width: auto;
            /* Chrome ignores auto, but here for completeness */
        }

        .k-link:hover {
            color: $ld-ford-dark-blue;
        }
    }
}

@media (min-width: 480px) {
    .card-container {
        text-align: initial;
        padding: 30px;

        &.grid {
            grid-template-columns: repeat(2, 1fr);
        }

        .card-title {
            grid-column: 1 / 2;
            grid-row: 1;
        }

        .card-buttons {
            text-align: center;
            grid-column: 1 / -1;
            grid-row: 2;
        }

        .card-ranges {
            text-align: right;
            grid-column: 2 / 3;
            grid-row: 1;
        }
    }

}

@media (min-width: 768px) {
    .card-container {
        margin: 25px 0 50px 0;
        border-radius: 5px;
        box-shadow: 0 0 10px rgba(0, 0, 0, .2);

        &.grid {
            grid-template-columns: repeat(3, 1fr);
        }

        .card-title {}

        .card-buttons {
            text-align: center;
            grid-column: 2 / 3;
            grid-row: 1;
        }

        .card-ranges {
            text-align: right;
            grid-column: 3 / 4;
        }

        .card-component {
            grid-column-start: 1;
            grid-column-end: -1;
        }
    }
}

// Header
.header {
    height: 80px;
    position: sticky;
    top: 0;
    z-index: 3;

    .nav-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        color: $black;
        border-bottom: 3px solid $ford-blue;
        .k-icon {
            color: $ford-blue;
        }
    }

    .menu-button {
        flex: 0 0 50px;
        text-align: center;
    }

    .hamburger-icon {
        background-image: url('./../assets/hamburger-icon.svg');
        background-repeat: no-repeat;
        width: 20px;
        cursor: pointer;
    }

    .title {
        display: flex;
        flex: 1 0 auto;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 25px;
    }

    .k-avatar {
        display: block;
        width: 40px;
        height: 40px;
        flex-basis: 40px;
        margin: 0 25px;
        box-shadow: 0 0 0px 1px $white;
    }

    .settings {
        display: none;
    }
}

@media (min-width: 480px) {
    .header {
        .title {
            flex-direction: row;
            align-items: center;
        }

        .vl {
            border-left: 1px solid $white;
            height: 25px;
            margin: 6px 15px;
        }
    }
}

@media (min-width: 768px) {
    .header {
        .settings {
            display: flex;
            flex: 0 0 auto;
            align-items: center;
            padding-right: 25px;
        }

        .k-dropdown {
            max-width: 90px;
            margin-left: 15px;
        }
    }
}

// privacy footer
.privacy-footer {
    //   position: absolute;
    bottom: 0;
}

// Dashboard page
.dashboard-page {
    min-height: 55vh;
    .card-container:last-of-type {
        padding-top: 0;
    }

    .k-grid-toolbar .k-textbox {
        margin-bottom: 10px;
    }

    .k-grid .k-virtual-content,
    .k-grid .k-virtual-content>div {
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;

        /* Firefox */
        &::-webkit-scrollbar {
            display: none;
        }
    }

    .k-badge {
        z-index: 1
    }

    .events_button {
        width: 100%;
        height: 60%;

    }
}

@media (min-width: 480px) {
    .dashboard-page {
        .k-grid-toolbar .k-textbox {
            margin-bottom: 0;
            float: left;
        }

        .k-grid-toolbar .k-button {
            float: right;
        }
    }
}

@media (min-width: 768px) {
    .dashboard-page {
        .card-container:last-of-type {
            padding-top: 30px;
        }
    }
}

// Event Planning page
.event-planning-page {
    .info-label {
        width: 130px;
        display: inline-block;
        font-weight: bold;
        margin: 10;
    }

    .card-container {
        grid-template-columns: repeat(1, 1fr);
    }

    .card-title {
        grid-column: 1 / -1;
    }

    .k-hbox {
        padding: 0 20px;
        background: transparent;
        align-items: center;
    }

    .k-card-title {
        font-size: 15px;
        margin-bottom: -6px;
        text-align: left;
    }

    .k-card-subtitle {
        margin-top: 0;
        text-transform: uppercase;
        font-size: 11px;
    }

    .disabled {
        opacity: 0.5;
    }
}

@media (min-width: 480px) {
    .event-planning-page {
        .card-container {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

@media (min-width: 768px) {
    .event-planning-page {
        .k-hbox {
            padding: 0;
            border-width: 0;
        }
    }
}

@media (min-width: 960px) {
    .event-planning-page {
        .card-container {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}

@media (min-width: 1360px) {
    .event-planning-page {
        .card-container {
            grid-template-columns: repeat(5, 1fr);
        }
    }
}

// Planning page
.planning-page {
    .card-container {
        grid-template-columns: repeat(1, 1fr);
    }

    .card-title {
        grid-column: 1 / -1;
    }

    .k-hbox {
        padding: 0 20px;
        background: transparent;
        align-items: center;
    }

    .k-card-title {
        font-size: 15px;
        margin-bottom: -6px;
        text-align: left;
    }

    .k-card-subtitle {
        margin-top: 0;
        text-transform: uppercase;
        font-size: 11px;
    }

    .disabled {
        opacity: 0.5;
    }
}

@media (min-width: 480px) {
    .planning-page {
        .card-container {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

@media (min-width: 768px) {
    .planning-page {
        .k-hbox {
            padding: 0;
            border-width: 0;
        }
    }
}

@media (min-width: 960px) {
    .planning-page {
        .card-container {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}

@media (min-width: 1360px) {
    .planning-page {
        .card-container {
            grid-template-columns: repeat(5, 1fr);
        }
    }
}

// Profile page
.profile-page {
    .card-container {
        text-align: left;
    }

    .k-form .k-form-field {
        display: block;
        padding: 0 15px;
    }

    .k-form-field:first-of-type .k-form-field-wrap {
        margin: auto;
    }

    .k-form-field:first-of-type .k-avatar {
        margin: 0 auto 1rem;
    }

    .k-form.k-form-horizontal .k-label {
        width: 100%;
    }

    hr {
        margin-top: 1rem;
        opacity: 0.2;
    }

    .k-form .k-form-buttons {
        justify-content: center;

        &>* {
            min-width: 140px;
        }
    }
}

@media (min-width: 480px) {
    .profile-page {
        .k-form .k-form-field {
            display: flex;
        }

        .k-form-field:first-of-type .k-avatar {
            margin: 0;
        }

        .k-form.k-form-horizontal .k-label {
            width: 25%;
        }
    }
}

// Resource page
.resource-page {
    .card-container {
        text-align: left;
    }

    hr {
        margin-top: 1rem;
        opacity: 0.2;
    }
}

@media (min-width: 480px) {
    .resource-page {
        .logo {
            margin: auto;
            display: block;
            max-width: 640px;
        }
    }
}

@media (min-width: 768px) {
    .profile-page {
        .k-form {
            margin: 0 auto;
            padding: 0;
        }

        .k-form .k-form-field {
            padding: 0;
        }
    }
}


// Info page
.info-page {
    background-color: $info-background;
    color: $white;

    h1,
    h2,
    h3,
    .component-link {
        font-family: Metric, Helvetica, Arial, sans-serif;
    }

    a:not(.k-button) {
        color: $link-color;
    }

    .content {
        padding-top: 40px;
        margin: 0 auto;
        position: relative;
        max-width: 820px;
    }

    .kendoka {
        text-align: center;

        img {
            max-width: 280px;
        }
    }

    .section-1 {
        text-align: center;

        h1 {
            font-size: 48px;
            font-weight: bold;
            color: $react-color;
        }

        h2 {
            font-size: 30px;
            margin-bottom: 53px;
        }

        .button-group {
            margin-bottom: 30px;

            .k-button {
                display: block;
                margin: 10px auto;
                min-width: 145px;
                width: 60%;
            }
        }

        .github-link {
            display: inline-flex;
            flex-direction: row;
            align-items: center;
            align-content: center;

            .github-text {
                margin-left: 15px;
            }
        }
    }

    .section-2 {
        text-align: center;
        margin-top: 40px;
        padding: 0 15px;
    }

    .section-3 {
        -webkit-columns: 2;
        column-count: 2;
        margin-top: 40px;
        padding: 0 15px;

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
        }
    }

    .package-item {
        display: inline-block;
        margin-top: 20px;
    }

    .package-title a {
        font-size: 17px;
        color: #CCCCCC;
        text-transform: uppercase;
    }

    .component-link {
        padding: 5px 0;

        a {
            font-size: 20px;
        }
    }

    .footer {
        background-image: url('../assets/info-background.svg');
        background-repeat: no-repeat;
        height: 320px;
    }
}

@media (min-width: 480px) {
    .info-page {
        .content {
            padding: 40px 40px 0;
        }

        .kendoka {
            img {
                max-width: 348px;
            }
        }

        .section-1 {
            h1 {
                font-size: 71px;
            }

            h2 {
                font-size: 38px;
            }

            .button-group {
                .k-button {
                    display: inline-block;
                    margin-right: 10px;
                    width: auto;
                }
            }
        }

        .section-3 {
            -webkit-columns: 3;
            column-count: 3;
        }
    }
}

@media (max-width: 1024px) {
    .k-form-horizontal .k-form-field-wrap {
        // max-width: 100%;
    }

}

@media (min-width: 768px) {
    .info-page {
        .content {
            padding: 120px 40px 0;
        }

        .kendoka {
            position: absolute;
            right: 0;
            top: 2em;
            text-align: left;
        }

        .section-1,
        .section-2 {
            text-align: left;
            padding: 0;
        }

        .section-3 {
            -webkit-columns: 4;
            column-count: 4;
            padding: 0;
        }

        .footer {
            background-size: cover;
        }
    }
}

// Guidelines page
.guidelines-page {
    background-color: $white;
    color: $ld-gray;
    width: 100%;
    min-height: 66vh;
    padding-bottom: 50px;
    color: $ford-blue;

    .logo {
        margin: auto;
        display: block;
        max-width: 340px;
        cursor: pointer;
    }

    .bold {
        font-family: FordF1-Semibold;
        color: $ford-blue;
        margin-bottom: 1rem;
    }

    .asterisk_li {
        list-style-type: none;
    }

    .asterisk_li::before {
        content: '*';
        display: inline-block;
        width: 1em;
        margin-left: -1em;
      }
    
    .content {
        padding: 1rem;
        margin: 0 auto;
        margin-bottom: 1rem;
        position: relative;
        max-width: 1070px;
        text-align: center;
        background-color: $light-gray;
        border-radius: 8px;
        width: 80%;
        .card-title {
            font-family: FordF1-Semibold !important;

        }

        p {
            font-family: FordF1-Medium;
            text-align: left;
        }

        li {
            text-align: left;
            margin-bottom: 0.5rem;
        }

        a {
            color: $ford-skyview;
            font-family: FordF1-Medium;
            font-size: 1rem;
        }

        span {
            color: $ford-skyview;
            font-family: FordF1-Medium;
            font-size: 1rem;
        }

        a:not(.k-button) {
            color: $ford-skyview;
        }

    }

    .faq_container {
        padding: 1rem;
        margin: 0 auto;
        margin-bottom: 1rem;
        position: relative;
        max-width: 1070px;
        text-align: center;
        background-color: $light-gray;
        border-radius: 8px;
        width: 80%;

        h3 {
            font-family: FordF1-Semibold !important;
            margin-bottom: 1rem;
        }

        h2 {
            font-family: FordF1-Bold !important;
            margin-bottom: 1rem;
        }

        ul {
            padding: 2rem;
            padding-top: 0;
        }

        li {
            text-align: left;
            margin-bottom: 1rem;

            p {
                margin-top: 5px;
                font-family: FordF1-Light;
            }
        }
    }

    span {
        color: $ld-gray;
    }

    .link {
        text-decoration: underline;
        color: $ld-ford-blue;
    }

    .a-list {
        display: block;
        margin: 10px 0;
    }

    // a:visited {
    //     color: $ld-gray
    // }

    .contact-content {
        width: 80%;
        .card-title {
            margin-bottom: 1rem;
        }

        p {
            text-align: center;
        }

        span {
            color: $ford-blue;
        }

    }
}

@media (min-width: 480px) {
    .guidelines-page {
        .logo {
            margin: auto;
            display: block;
            max-width: 340px;
        }
    }
}

@media (min-width: 480px) {
    .home-page {
        .content {
            padding: 40px 40px 0;
        }

        .logo {
            margin: auto;
            display: block;
            max-width: 240px;
        }

        .section-1 {
            h1 {
                font-size: 71px;
            }

            h2 {
                font-size: 38px;
            }

            .button-group {
                .k-button {
                    display: inline-block;
                    margin-right: 10px;
                    width: auto;
                }
            }
        }

        .section-3 {
            -webkit-columns: 3;
            column-count: 3;
        }
    }
}

@media (max-width: 480px) {
    .k-form-horizontal .k-form-field-wrap {
        max-width: 100%;
    }

}

@media (min-width: 768px) {
    .home-page {
        .content {
            padding: 20px 40px 0;
        }

        .section-1,
        .section-2 {
            text-align: left;
            padding: 0;
        }

        .section-3 {
            -webkit-columns: 4;
            column-count: 4;
            padding: 0;
        }

        .footer {
            background-size: cover;
        }
    }
}

.btn-disabled {
    background-color: grey !important;
    border-color: grey !important;
}

.min-height {
    min-height: 66vh;
}

.not_found {
    p {
        font-family: FordF1-Bold;
        color: $ford-blue;
        font-size: 3rem;
        margin-top: 3rem;
    }

    div {
        //center all children
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

// menu bar
.menu_items_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 3px solid $ford-blue;
    background-color: $light-gray;

    .img_container {
        width: 33%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    img {
        padding: 0;
        margin: 0;
        max-width: 150px;
        cursor: pointer;
    }
    .nav-menu {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 0;
        margin: 0;
        width: 33%;
    }

    p {
        color: $ford-blue;
        font-family: FordF1-Semibold;
        font-size: 1rem;
        cursor: pointer;
        margin: 0;
        padding: 6px 12px;
        width: 33%;
        text-align: end;
    }

    p:hover {
        color: $button-hover;
    }
    
    .k-menu {
        margin: 0;
        padding: 0;
        line-height: normal;
    }
    .k-menu-link {
        padding: 6px 12px;
        color: $ford-blue;
        font-family: FordF1-Semibold;
        font-size: 1rem;
    }

    .k-menu-link:hover {
        color: $button-hover;
    }
}

.k-button:disabled {
    background-color: #E5E5E5 !important;
    border: 2px solid #808080;
    color: #808080;
}

// footer
.ca-banner {
    font-family: FordF1-Regular;
    .container-ford {
        padding: 1rem;
    }
    .ford-primary-button {
        font-family: FordF1-Regular;
        color: $white;
        border-radius: 400px;
        background-color: $ford-skyview;
    }
    .privacyContainer-ford {
        text-transform: uppercase;
        width: 100% !important;
        padding: 1rem !important;
        margin-left: 0 !important;
        .privacyContainerLink-ford {
            color: $ford-blue;
        }
        .privacyContainerLink-ford:hover {
            color: $button-hover;
        }
    }
    .footer-img-ford {
        margin-right: 2rem;
        margin-bottom: 1rem;
    }
}

.k-drawer-container {
    .k-state-selected {
        background-color: $button-hover;
        .k-item-text {
            font-family: FordF1-Medium;
            color: white !important;
        }

        .k-icon {
            color: white !important;
        }
    }
    .k-drawer-item {
        .k-icon {
            color: $ford-blue;
        }
        .k-item-text {
            font-family: FordF1-Semibold;
            color: $ford-blue;
        }
    }

}

.k-drawer-content {
    width: 100vw;
}