body {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

html,
body {
  &::-webkit-scrollbar {
    display: none;
  }
}

@font-face {
  font-family: 'FordMotion';
  font-weight: 400;
  src: url('//cdn.latitudewebservices.com/fonts/f1/regular.woff2') format("woff2"), url('//cdn.latitudewebservices.com/fonts/f1/regular.woff') format("woff")
}

@font-face {
  font-family: 'FordF1-Light';
  font-weight: 100 300;
  src: url('//cdn.latitudewebservices.com/fonts/f1/light.woff2') format("woff2"), url('//cdn.latitudewebservices.com/fonts/f1/light.woff') format("woff")
}

@font-face {
  font-family: 'FordF1-Regular';
  font-weight: 400;
  src: url('//cdn.latitudewebservices.com/fonts/f1/regular.woff2') format("woff2"), url('//cdn.latitudewebservices.com/fonts/f1/regular.woff') format("woff")
}

@font-face {
  font-family: 'FordF1-Medium';
  font-weight: 500;
  src: url('//cdn.latitudewebservices.com/fonts/f1/medium.woff2') format("woff2"), url('//cdn.latitudewebservices.com/fonts/f1/medium.woff') format("woff")
}

@font-face {
  font-family: 'FordF1-Semibold';
  font-weight: 600;
  src: url('//cdn.latitudewebservices.com/fonts/f1/semibold.woff2') format("woff2"), url('//cdn.latitudewebservices.com/fonts/f1/semibold.woff') format("woff")
}

@font-face {
  font-family: 'FordF1-Bold';
  font-weight: 700 900;
  src: url('//cdn.latitudewebservices.com/fonts/f1/bold.woff2') format("woff2"), url('//cdn.latitudewebservices.com/fonts/f1/bold.woff') format("woff")
}

@import "./styles/main.scss";