@import 'variables';
.events_page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.grid_container {
    background-color: $light-gray;
    height: 90%;
    min-height: 55vh;
    margin: 2rem;
    padding: 1rem;
    border-radius: 8px;
    width: 90vw;
    // margin-left: 10%;

    .container_header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        .btn_container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;

            .waitlist-disclaimer {
                color: $input-error;
            }
            p {
                color: $ford-blue;
                margin-top: 0;
                font-size: 0.8rem;
                padding: 1rem;
            }

        }
        .standard_btn {
            background-color: $ford-skyview;
            margin-top: 3px;
        }

        .wide {
            width: 200px;
        }

        .standard_btn:hover {
            background-color: $button-hover;
        }
    }

    .card-title {
        color: $ford-blue;
        font-family: FordF1-Semibold;
        font-size: 2rem;
        margin-top: 0;
    }

}

.k-grid {
    .small_events_btn {
        display: none;
    }
    td {

    }
    .k-grid-header {
    
        .k-header {

        }

        .k-cell-inner {
            background-color: $ford-blue;
            color: white;
            font-family: FordF1-Semibold;
        }
    }

    .action_cell_container {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: flex-start;
        background-color: $light-gray;
        // .k-button {
        //     width: 50px;
        //     white-space: nowrap;
        // }
    }

    @media only screen and (max-width: 568px) {
        .grid_container {
            width: 99vw;
        }
        .action_cell_container {
            padding: 10px;
            margin-left: 0;
        }
        .small_events_btn {
            // width: 25px;
            font-size: 10px;
            display: flex;
            border: none;
            background-color: transparent;
            font-family: FordF1-Medium;
            text-align: center;
            color: $ford-skyview;
            cursor: pointer;
            padding: 5px;
        }

        .view_details_btn {
            display: none;
        }
    }

}
