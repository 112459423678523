@import 'variables';

.eligibility-page {
    width: 100vw;
}

.eligibility-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;

    .logo {
        max-width: 350px;
        width: 100%;
        cursor: pointer;
    }

    .form-container {
        margin: 1rem;
        margin-bottom: 2rem;
        padding: 2rem;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: $light-gray;
        width: 80%;

        .k-form {
            width: 90%;
            max-width: 700px;
            .k-form-hint {
                color: $ford-blue;
            }
        }

        #agree_terms_label {
            font-family: FordF1-Semibold !important;
        }

        .inner-wrapper, .k-calendar, table, td.k-today, span.k-link, .inner-wrapper, .k-calendar, div.k-calendar-header, span {
	        color: $ford-blue;
        }


        .title {
            color: $ford-blue;
            font-family: FordF1-Medium !important;
        }

        .request-title {
            color: $ford-blue;
            font-family: FordF1-Semibold !important;
            text-align: left;
            width: 100%;
            font-size: 2rem;
        }

        .waiver-title {
            color: $ford-blue;
            font-family: FordF1-Semibold !important;
            text-align: left;
            width: 100%;
            font-size: 1.5rem;
        }

        .waiver_bold_text {
            font-family: FordF1-Semibold;
        }

        .request-text {
            font-family: FordF1-Regular;
            color: $ford-blue;

            a {
                color: $ford-skyview;
                text-decoration: underline;
            }
        }

        .terms_link {
            color: $ford-skyview;
            font-family: FordF1-Semibold;
            font-style: italic;
            font-size: 0.8rem;
        }
        .k-form-field {
            margin-bottom: 1rem;
            flex-direction: column;

            .k-label {
                margin-bottom: 5px;
                align-items: flex-start;
                color: $ford-blue !important;
                font-family: FordF1-Regular;
                width: auto;
            }

            .k-textbox {
                border: 1px solid #808080;
                border-radius: 8px;
                line-height: 20px;
                padding: 8px 12px 8px 12px;
                font-size: 14px;
                font-family: FordF1-Regular;
            }

            .k-state-invalid {
                border: 2px solid $input-error;
                border-radius: 8px;
            }

            .k-form-error {
                color: $input-error;
            }

            .k-dropdown-wrap .k-picker-wrap {
                background-color: white !important;
                border: 1px solid #808080;
                border-radius: 8px;
            }

            .k-dropdown {
                .k-dropdown-wrap {
                    background-color: white;
                    border: 1px solid #808080;
                    border-radius: 8px;
                }
            }

            .k-datepicker {
                .k-picker-wrap {
                    background-color: white;
                    border: 1px solid #808080;
                    border-radius: 8px;

                    .k-dateinput {}

                    .k-select {
                        background-color: $light-gray;
                    }
                }
            }

            .checkbox_wrapper {
                display: flex;
                flex-direction: row;

                a {
                    color: $ford-skyview;
                    font-family: FordF1-Semibold;
                    font-size: 0.8rem;
                }

                .k-checkbox {
                    border: 1px solid #808080;
                }

                .k-checkbox::before {
                    border: 1px solid $ford-skyview;
                    background-color: $ford-skyview;
                }

                .k-checkbox:checked {
                    border: 1px solid $ford-skyview;
                    background-color: $ford-skyview;
                }

                p {
                    padding: 0;
                    margin: 0;
                    font-family: FordF1-Light;
                    color: $ford-blue;
                }

                .k-state-invalid {
                    border-radius: 0;
                }

            }





        }


        .standard_btn {
            background-color: $ford-skyview;
        }

        .wide {
            width: 200px;
        }

        .cancel {
            background-color: $ford-twilight;
        }

        .standard_btn:hover {
            background-color: $button-hover;
        }

        .form_wrapper {
            width: 100%;
        }
    }

    .login_container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        .login_text {
            font-family: FordF1-Regular;
            font-size: 1rem;
            color: $ford-blue;
        }

        .login_btn {
            background-color: transparent;
            border: none;
            color: $ford-skyview;
            font-family: FordF1-Regular;
            text-decoration: underline;
            font-size: 1rem;
            cursor: pointer;
        }

        .login_btn:hover {
            color: $button-hover;
        }
    }

    .vertical_btn_container {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

}

@media (max-width: 1024px) { 
    .logo_container {
        display: flex;
        justify-content: center;
    }

    .signup-content {
        .login_container {
            justify-content: center;
        }
    }

    .eligibility-content {
        .vertical_btn_container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

    }

}