@import 'variables';

.main {
    // border: 2px solid red;
    background-color: $ld-light-gray;
    color: $ld-gray;
    width: 100%;
    min-height: 66vh;
    padding-bottom: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .logo {
        max-width: 270px;
        width: 80%;
        cursor: pointer;
    }

    .left_content {
        padding: 1rem;
        padding-top: 4rem;
        width: 34%;

        .forgot-text {
            color: $ford-blue;
            font-size: .8rem;
            margin-top: 0;
        }

        .form-container {
            margin-left: 2rem;
            .k-form-field {
                margin-bottom: 1rem;
                flex-direction: column;
                
                .k-label {
                    margin-bottom: 5px;
                    align-items: flex-start;
                    color: $ford-blue !important;
                    font-family: FordF1-Regular;
                    width: auto;
                }
                
                .k-textbox {
                    border: 1px solid #808080;
                    border-radius: 8px;
                    line-height: 20px;
                    padding: 8px 12px 8px 12px;
                    font-size: 14px;
                    font-family: FordF1-Regular;
                }

                .k-state-invalid {
                    border: 2px solid $input-error;
                }

                .k-form-error {
                    color: $input-error;
                }

            }

            .form_title {
                font-family: FordF1-Regular !important;
                color: $ford-blue;
                margin-bottom: 2rem;
                margin-top: 2rem;
                font-size: 3rem;
            }
        }

        .forgot_pass_container {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            width: 100%;
            margin-bottom: 1rem;
            padding: 0;
            .forgot_pass_btn {
                background-color: transparent;
                border: none;
                color: $ford-skyview;
                font-family: FordF1-Regular;
                text-decoration: underline;
                font-size: 1rem;
                cursor: pointer;
                padding: 0;
            }
            .forgot_pass_btn:hover {
                color: $button-hover;
            }
        }

        .log_in_btn {
            font-family: FordF1-Medium;
            font-size: 14px;
            color: $white;
            margin: 0;
            margin-bottom: 1rem;
            background-color: $ford-skyview;
            border-style: none;
            border-radius: 400px;
            box-sizing: content-box;
            // text-transform: uppercase;
            cursor: pointer;
            line-height: 20px;
            text-align: center;
            // display: inline;
            height: 28px;
            padding: 12px 16px 12px 16px;
            perspective-origin: 0 0;
            text-decoration: none;
            transform-origin: 0 0;
            width: 135px;
            // -moz-appearance: none;
            // -webkit-logical-height: 1em;
            // /* Chrome ignores auto, so we have to use this hack to set the correct height  */
            // -webkit-logical-width: auto;
            /* Chrome ignores auto, but here for completeness */
        }
        .log_in_btn:hover {
            background-color: $button-hover;
        }

        .sign_up_container {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            .sign_up_text {
                font-family: FordF1-Regular;
                font-size: 1rem;
                color: $ford-blue;
            }

            .sign_up_btn {
                background-color: transparent;
                border: none;
                color: $ford-skyview;
                font-family: FordF1-Regular;
                text-decoration: underline;
                font-size: 1rem;
                cursor: pointer;
            }
            .sign_up_btn:hover {
                color: $button-hover;
            }
        }
        .reset_container {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            .sign_up_text {
                font-family: FordF1-Regular;
                font-size: 1rem;
                color: $ford-blue;
            }

            .sign_up_btn {
                background-color: transparent;
                border: none;
                color: $ford-skyview;
                font-family: FordF1-Regular;
                text-decoration: underline;
                font-size: 1rem;
                cursor: pointer;
            }
            .sign_up_btn:hover {
                color: $button-hover;
            }
        }
    }

    .right_content {
        // border: 3px solid orange;
        width: 66%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        border-radius: 20px 20px 20px 20px;

        .right_img {
            width: 90%;
            border-radius: 20px 0px 0px 20px;
            max-width: 1200px;
            margin-left: 1rem;
        }
    }
}

@media (max-width: 1024px) {
    .main {
        flex-direction: column;
        .left_content {
            width: 90vw;
            margin-left: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-top: 1rem;
            .form-container {
                margin-left: 0;
                .forgot_pass_container {
                    width: 100%;
                    justify-content: flex-start;
                    margin-bottom: 1rem;
                    
                }
                .log_in_button {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
            }
            // .sign_up_container {
            //     display: flex;
            //     flex-direction: row;
            //     justify-content: center;
            //     align-items: center;
            // }
            // .forgot-text {
            //     text-align: center;
            // }
        }
        .right_content {
            width: 100vw;
            margin-left: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-top: 1rem;
            .right_img {
                border-radius: 20px;
                margin-left: 0;
            }
        }
    }
}

// handle ipad sizes
@media (min-width: 477px) and (max-width: 1024px) {
    .main {
        .left_content {
            .form-container {
                width: 70vw;
            }
        }
    }
}