@import 'variables';

.signup-page {
    width: 100vw;
}

.signup-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;

    .logo {
        max-width: 350px;
        width: 100%;
        cursor: pointer;
    }

    .form-container {
        margin: 1rem;
        margin-bottom: 2rem;
        padding: 2rem;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: $light-gray;
        width: 80%;
        max-width: 100%;  // Ensure container doesn't overflow
        box-sizing: border-box; 

        #event_location_type_hint, #start_date_hint, #parts_code_hint, #dealer_name_hint, #address_hint  {
            color: $ford-blue;
        }

        #agree_terms_label {
            font-family: FordF1-Semibold !important;
        }

        .inner-wrapper, .k-calendar, table, td.k-today, span.k-link, .inner-wrapper, .k-calendar, div.k-calendar-header, span {
	        color: $ford-blue;
        }


        .title {
            color: $ford-blue;
            font-family: FordF1-Medium !important;
        }

        .request-title {
            color: $ford-blue;
            font-family: FordF1-Semibold !important;
            text-align: left;
            width: 100%;
            font-size: 2rem;
        }

        .waiver-title {
            color: $ford-blue;
            font-family: FordF1-Semibold !important;
            text-align: left;
            width: 100%;
            font-size: 1.5rem;
        }

        .waiver_bold_text {
            font-family: FordF1-Semibold;
        }

        .request-text {
            font-family: FordF1-Regular;
            color: $ford-blue;

            a {
                color: $ford-skyview;
                text-decoration: underline;
            }
        }

        .terms_link {
            color: $ford-skyview;
            font-family: FordF1-Semibold;
            font-style: italic;
            font-size: 0.8rem;
        }

        .k-form-field {
            margin-bottom: 1rem;
            flex-direction: column;

            .k-label {
                margin-bottom: 5px;
                align-items: flex-start;
                color: $ford-blue !important;
                font-family: FordF1-Regular;
                width: auto;
            }

            .k-textbox {
                border: 1px solid #808080;
                border-radius: 8px;
                line-height: 20px;
                padding: 8px 12px 8px 12px;
                font-size: 14px;
                font-family: FordF1-Regular;
            }

            .k-state-invalid {
                border: 2px solid $input-error;
                border-radius: 8px;
            }

            .k-form-error {
                color: $input-error;
            }

            .k-dropdown-wrap .k-picker-wrap {
                background-color: white !important;
                border: 1px solid #808080;
                border-radius: 8px;
            }

            .k-dropdown {
                .k-dropdown-wrap {
                    background-color: white;
                    border: 1px solid #808080;
                    border-radius: 8px;
                }
            }

            .k-datepicker {
                .k-picker-wrap {
                    background-color: white;
                    border: 1px solid #808080;
                    border-radius: 8px;

                    .k-dateinput {}

                    .k-select {
                        background-color: $light-gray;
                    }
                }
            }

            .checkbox_wrapper {
                display: flex;
                flex-direction: row;

                a {
                    color: $ford-skyview;
                    font-family: FordF1-Semibold;
                    font-size: 0.8rem;
                }

                .k-checkbox {
                    border: 1px solid #808080;
                }

                .k-checkbox::before {
                    border: 1px solid $ford-skyview;
                    background-color: $ford-skyview;
                }

                .k-checkbox:checked {
                    border: 1px solid $ford-skyview;
                    background-color: $ford-skyview;
                }

                p {
                    padding: 0;
                    margin: 0;
                    font-family: FordF1-Light;
                    color: $ford-blue;
                }

                .k-state-invalid {
                    border-radius: 0;
                }
            }
        }


        .standard_btn {
            background-color: $ford-skyview;
        }

        .wide {
            width: 200px;
        }

        .cancel {
            background-color: $ford-twilight;
        }

        .standard_btn:hover {
            background-color: $button-hover;
        }

        .form_wrapper {
            width: 100%;
        }
    }

    .contact-fields-grid {
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
        margin-bottom: 10px;
        width: 100%;
        max-width: 100%; 
        box-sizing: border-box; 
        grid-gap: 15px;
    
        .contact-column {
            width: 100%;
            box-sizing: border-box;
            margin-bottom: 10px;
            
            .k-form-field {
                width: 100%;
                box-sizing: border-box;
                margin: 0;

                .k-label {
                    margin-bottom: 8px;
                    text-align: left;
                    width: 100%;
                    font-size: 14px;
                    white-space: nowrap;
                    overflow: hidden; 
                }

    
                .k-textbox, .k-maskedtextbox, .k-input {
                    width: 100%;
                    max-width: 100%;
                    box-sizing: border-box;
                }
                .k-form-hint {
                    font-style: italic;
                    font-size: 0.85em;
                    margin-top: 4px;
                }
            }
        }
    }
    
    @media (min-width: 850px) {
        .contact-fields-grid {
            grid-template-columns: repeat(3, 1fr);

            .k-form-field {
                .k-textbox, .k-maskedtextbox, .k-input {
                    min-width: 0; 
                }

                .k-form-field-wrap, .k-horizontal, .k-label {
                    max-width: 95%;
                }
            }
        }
    }

    .login_container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        .login_text {
            font-family: FordF1-Regular;
            font-size: 1rem;
            color: $ford-blue;
        }

        .login_btn {
            background-color: transparent;
            border: none;
            color: $ford-skyview;
            font-family: FordF1-Regular;
            text-decoration: underline;
            font-size: 1rem;
            cursor: pointer;
        }

        .login_btn:hover {
            color: $button-hover;
        }
    }

    .vertical_btn_container {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

}

@media (max-width: 1024px) { 
    .logo_container {
        display: flex;
        justify-content: center;
    }

    .signup-content {
        .login_container {
            justify-content: center;
        }
    }

    .create_btn_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

.location-fields-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .location-column {
    width: 100%;
  }
  
  /* Update the contact-fields-grid to ensure wider inputs */
  .contact-fields-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .contact-column {
    width: 100%;
  }
  
  /* Ensure inputs take full width of their containers */
  .contact-column .k-textbox,
  .contact-column .k-maskedtextbox,
  .contact-column .k-dropdown,
  .location-column .k-textbox,
  .location-column .k-maskedtextbox,
  .location-column .k-dropdown {
    width: 100%;
  }